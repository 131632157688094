import { Injectable, inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MatIconRegistryService {
  private readonly matIconRegistry = inject(MatIconRegistry);
  private readonly sanitizer = inject(DomSanitizer);

  registerSvgIcons(): void {
    this.matIconRegistry.addSvgIcon(
      'google',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/google.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'facebook',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/facebook-logo.svg'),
    );

    // share
    this.matIconRegistry.addSvgIcon(
      'facebook-share',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/fontawesome-free-6.4.2-web/svgs/brands/facebook-f.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'x-twitter-share',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/fontawesome-free-6.4.2-web/svgs/brands/x-twitter.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'linked-in-share',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/fontawesome-free-6.4.2-web/svgs/brands/linkedin-in.svg'),
    );

    this.matIconRegistry.addSvgIconResolver((name, _) => {
      return this.sanitizer.bypassSecurityTrustResourceUrl(`assets/flags/${name}.svg`);
    });
  }
}
