import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { provideTransloco, translocoConfig } from '@ngneat/transloco';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { httpErrorInterceptor } from 'src/app/core/interceptors/http-error.interceptor';
import { tokenInterceptor } from 'src/app/core/interceptors/token.interceptor';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { environment } from 'src/environments/environment';

import { MatIconRegistryService } from './core/icons/mat-icon-registry.service';
import { APP_ROUTES } from './routes';
import { TranslocoHttpLoader, translocoAppConfig } from './core/translations/transloco-loader';

export function initializeApp(matIconRegistryService: MatIconRegistryService): () => void {
  return () => {
    /**
     * Update in the future if additional initialization logic needed in the app.
     * Return Observable<...> if need to do async task on initialization (such as load some config etc.)
     */
    matIconRegistryService.registerSvgIcons();
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [MatIconRegistryService],
      multi: true,
    },
    provideAnimations(),
    provideHttpClient(withInterceptors([httpErrorInterceptor, tokenInterceptor])),
    importProvidersFrom([
      MatSnackBarModule, // we need this here as we use the snackbar in the interceptor,
      MatNativeDateModule,
      SocialLoginModule,
    ]),

    // httpInterceptorProviders,
    provideRouter(APP_ROUTES, withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })),
    provideTransloco({
      config: translocoConfig(translocoAppConfig),
      loader: TranslocoHttpLoader,
    }),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' } as MatFormFieldDefaultOptions,
    },
    // { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    // { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 6000 } },
    // date and time format
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },

    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.google_app_id, { oneTapEnabled: false }),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebook_app_id),
          },
        ],
      } satisfies SocialAuthServiceConfig,
    },
  ],
};
