import { Routes } from '@angular/router';
import { PageNotFoundComponent } from '@app-shared/components/page-not-found/page-not-found.component';
import { authGuard } from 'src/app/core/guards/auth.guard';
import { gearSubdomainGuard } from 'src/app/core/guards/gear-subdomain.guard';
import { notAuthGuard } from 'src/app/core/guards/not-auth.guard';

export const APP_ROUTES: Routes = [
  {
    path: 'sso',
    loadChildren: () => import('./sso/sso.routes').then(routes => routes.SSO_ROUTES),
  },
  {
    path: '',
    loadChildren: () => import('./auth/routes').then(routes => routes.AUTH_ROUTES),
    canActivate: [notAuthGuard],
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/routes').then(routes => routes.DASHBOARD_ROUTES),
    canActivate: [authGuard],
  },
  // verification
  {
    path: '',
    loadChildren: () => import('./verification/routes').then(routes => routes.VERIFICATION_ROUTES),
  },
  // assessment routes
  {
    path: '',
    loadChildren: () => import('./assessments/routes').then(routes => routes.ASSESSMENTS_ROUTES),
    canActivate: [authGuard],
  },
  // anon assessment routes
  {
    path: '',
    loadChildren: () => import('./assessments/routes').then(routes => routes.ANON_ASSESSMENTS_ROUTES),
  },
  // assessment invite link routes
  {
    path: '',
    loadChildren: () => import('./assessments/routes').then(routes => routes.INVITE_LINK_ROUTES),
  },
  // share routes
  {
    path: '',
    loadChildren: () => import('./assessments/routes').then(routes => routes.SHARE_ROUTES),
  },
  {
    path: `privacy-policy`,
    loadComponent: () =>
      import('./policies/privacy-policy/privacy-policy.component').then(c => c.PrivacyPolicyComponent),
    canActivate: [gearSubdomainGuard],
  },
  {
    path: `cookie-policy`,
    loadComponent: () => import('./policies/cookie-policy/cookie-policy.component').then(c => c.CookiePolicyComponent),
    canActivate: [gearSubdomainGuard],
  },

  // page not found
  {
    path: 'not-found',
    component: PageNotFoundComponent,
  },
  { path: '**', redirectTo: '/not-found', pathMatch: 'full' },
];
