<section *transloco="let t">
  <h2>404</h2>
  <h1>
    {{ t('titles.notFound') }}
  </h1>
  <p>
    {{ t('messages.notFound') }}
  </p>
  <button mat-raised-button routerLink="/" color="primary">
    {{ t('global.gotToHomepage') }}
  </button>
</section>
