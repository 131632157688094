/**
 * Makes a script element with provided url as src and appends it to the body
 * @param url
 */
export function loadScript(url: string, config?: { innerHTML?: string; id?: string }): void {
  if (!document.querySelector(`script[src='${url}']`)) {
    const script = document.createElement('script');
    script.src = url;
    if (config) {
      if (config.innerHTML) script.innerHTML = config.innerHTML;
      if (config.id) script.id = config.id;
    }
    document.body.appendChild(script);
  }
}
