import { enableProdMode } from '@angular/core';
import { enableElfProdMode } from '@ngneat/elf';
import { bootstrapApplication } from '@angular/platform-browser';
import { devTools } from '@ngneat/elf-devtools';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { loadScript } from './app/core/functions/script-loader';

if (environment.production) {
  enableProdMode();
  enableElfProdMode();
} else {
  devTools();
}

loadScript('https://connect.facebook.net/en_US/sdk.js');

// eslint-disable-next-line no-console
bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
