import { HttpClient } from '@angular/common/http';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, of as just } from 'rxjs';
import { PartialTranslocoConfig } from '@ngneat/transloco/lib/transloco.config';
import { LanguageCode, LanguageCodes } from '@app-shared/enums/language-codes.enum';
import { environment } from 'src/environments/environment';
import { languageCodeToBackendExpectedLanguageMap } from '@app-core/translations/enums/available-language.enum';
import { SkipErrorInterceptor } from '@app-core/interceptors/http-error.interceptor';
import { SkipTokenInterceptor } from '@app-core/interceptors/token.interceptor';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  getTranslation(lang: LanguageCodes): Observable<Translation> {
    return this.http
      .get<Translation>(
        `${environment.translationsUrl}/messages%2Bintl-icu.${languageCodeToBackendExpectedLanguageMap.get(lang)}.json`,
        {
          headers: {
            [SkipErrorInterceptor]: '',
            [SkipTokenInterceptor]: '',
          },
        },
      )
      .pipe(
        catchError(() => {
          return just({});
        }),
      );
  }
}
export const translocoAppConfig: PartialTranslocoConfig = {
  availableLangs: Object.values(LanguageCode),
  defaultLang: LanguageCode.ENGLISH_USA,
  fallbackLang: LanguageCode.ENGLISH_USA,
  missingHandler: {
    // It will use the first language set in the `fallbackLang` property
    useFallbackTranslation: true,
  },
  // Remove this option if your application doesn't support changing language in runtime.
  reRenderOnLangChange: true,
  prodMode: environment.production,
};
