import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { RouterOutlet } from '@angular/router';
import { LanguageCode, shortCodeLanguageToLanguageCodeMap } from '@app-shared/enums/language-codes.enum';
import { SettingsRepository } from 'src/app/core/store/settings/settings.repository';
import { isGearSubdomain } from '@app-shared/helpers/helpers';
import { DOCUMENT } from '@angular/common';
import { updateHTMLLangProperty } from '@app-shared/functions/utility';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet],
})
export class AppComponent {
  private readonly document = inject(DOCUMENT);
  private readonly transloco = inject(TranslocoService);
  private readonly settingsRepo = inject(SettingsRepository);

  constructor() {
    this.settingsRepo.setStoreProp('isGearSubdomain', isGearSubdomain(this.document.defaultView?.location.hostname));

    const savedLanguage = this.settingsRepo.getStoreProp('language');

    // if user language is set in application repository use selected language
    if (savedLanguage) {
      this.transloco.setActiveLang(savedLanguage);
    }
    // else check user browser language
    else {
      const browserLang = navigator.language;
      const availableLangs = Object.values(LanguageCode);
      const foundLanguage = availableLangs.find(lang => lang === browserLang);

      // If we support the language that the user has set in his browser,
      // we set that one as our app language
      if (foundLanguage) {
        this.settingsRepo.setLanguage(foundLanguage);
      }
      // Else we check in our short code language map and set the appropriate language
      else {
        this.settingsRepo.setLanguage(shortCodeLanguageToLanguageCodeMap.get(browserLang));
      }
    }

    // Update HTML lang property on language change
    this.settingsRepo.language$.subscribe({
      next: lang => {
        updateHTMLLangProperty(this.document, lang);
      },
    });
  }
}
