import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs';
import { SessionRepository } from 'src/app/core/store/session/session.repository';

export const notAuthGuard: CanActivateFn = (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
  const router = inject(Router);
  const sessionRepo = inject(SessionRepository);

  return sessionRepo.isLoggedIn$.pipe(
    map(loggedIn => {
      if (loggedIn) {
        router.navigateByUrl('/my-assessments');
      }

      return !loggedIn;
    }),
  );
};
